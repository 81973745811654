export default {
    db: {
        waitlist: "waitlist"
    },
    events: {
        travelersWaitlist: "travelers_waitlist_signup",
        communityWaitlist: "community_waitlist_signup",
        tourguideWaitlist: "tour_guide_waitlist_signup",
    },
    audience: {
        travelers: "travelers",
        communityManager: "community_manager",
        tour_guides: "tour_guides",
        bloggers: "bloggers"
    }

}