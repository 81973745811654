import React from 'react';
import Feature from './Feature';
import { FaGlobeAmericas } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { HiMiniChatBubbleLeftRight } from "react-icons/hi2";
import { BiTrip } from "react-icons/bi";
import { FaMapMarkedAlt } from "react-icons/fa";
import { HiOutlinePencil } from "react-icons/hi";

export default function Features() {
  const { t } = useTranslation();

  const features = [
    {
      icon: HiOutlinePencil,
      title: t("features.traveler_tips.title"),
      description: t("features.traveler_tips.description")
    },
    {
      icon: BiTrip,
      title: t("features.trip_planning.title"),
      description: t("features.trip_planning.description")
    },
    {
      icon: FaGlobeAmericas,
      title: t("features.local_communities.title"),
      description: t("features.local_communities.description")
    },
    {
      icon: FaMapMarkedAlt,
      title: t("features.explore_destinations.title"),
      description: t("features.explore_destinations.description")
    }
  ];

  return (
    <div className="py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="p-2 text-4xl font-bold mb-6 bg-gradient-to-r from-brand-navy to-brand-teal bg-clip-text text-transparent">
            {t("features.features_section_title")}
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {features.map((feature, index) => (
            <Feature key={index} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
}