import React from 'react';
import blogger from '../../assets/blogger.jpeg';
import Button from '../Button';
import styles from '../../utility/styles';
import { useTranslation } from 'react-i18next';

export default function Hero({ actionBtnPress }) {
  const { t } = useTranslation();

  return (
    <div className="hero relative bg-gradient-to-r from-brand-navy to-brand-teal overflow-hidden  pt-[var(--navbar-height)]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-10 pb-16 grid lg:grid-cols-2 gap-16 items-center">
        {/* Section: Text */}
        <div className="space-y-6 lg:space-y-8">
          <h1 className="text-4xl lg:text-5xl font-bold text-white leading-tight">
            <span>{t("bloggers.hero_h")}</span><br />
            <span>{t("bloggers.hero_s")}</span>
          </h1>

          <p className="text-lg lg:text-xl text-gray-200 leading-relaxed max-w-lg">
            {t("bloggers.hero_p")}
          </p>

          <Button
            text={t("join_waitlist")}
            className={styles.button.bigger + " " + styles.button.primary}
            onClick={actionBtnPress}
          />
        </div>

        {/* Section: Image */}
        <div className="relative">
          <img
            src={blogger}
            alt="Tour guide showing amazing views"
            className="w-full h-[400px] lg:h-[500px] object-cover rounded-2xl shadow-2xl"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-brand-navy/50 to-transparent rounded-2xl"></div>
        </div>
      </div>
    </div>

  );
}
