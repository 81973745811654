import React, { useState } from "react";
import { logEvent } from "firebase/analytics";
import { collection, addDoc } from "firebase/firestore";
import { db, analytics } from "../../firebase";
import { useTranslation } from 'react-i18next';
import constants from "../../utility/constants";
import { TbMoodHappy } from "react-icons/tb";

import { RiEmotionHappyLine } from "react-icons/ri";

const WaitlistForm = ({ visible, dismiss, audience }) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    fullName: "",
    pageLink: "",
    email: "",
    audienceSize: 5000,
  });

  const maxMembers = 100000;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSliderChange = (e) => {
    setFormData({ ...formData, audienceSize: e.target.value });
  };

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await addDoc(collection(db, "waitlist"), {
        ...formData,
        audience,
        date: new Date(),
      });
      logEvent(analytics, constants.events.communityWaitlist, { audience, ...formData });
      alert(t("thankYouMessage"));
      setFormData({ fullName: "", communityName: "", pageLink: "", email: "", audienceSize: 0 });
      dismiss();
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("An error occurred. Please try again.");
    }
  };

  if (!visible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6 relative">
        <button
          onClick={dismiss}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 text-2xl"
        >
          &times;
        </button>

        <h2 className="text-2xl font-bold text-center mb-4 text-gray-700">
          {t("waitlist.title")} {/* Example: "Join the Waitlist" */}
        </h2>
        <p className="text-sm text-center text-gray-500 mb-6">
          {t("waitlist.subtitle")} {/* Example: "Be among the first to access our platform..." */}
        </p>

        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            name="fullName"
            placeholder={t("waitlist.fullNamePlaceholder")} // Example: "Full Name"
            value={formData.fullName}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-teal-500 focus:outline-none"
          />

          <input
            type="email"
            name="email"
            placeholder={t("waitlist.emailPlaceholder")} // Example: "Email Address"
            value={formData.email}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-teal-500 focus:outline-none"
          />

          <input
            type="url"
            name="pageLink"
            placeholder={t("waitlist.linkPlaceholder")} // Example: "Community Link or Website"
            value={formData.pageLink}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-teal-500 focus:outline-none"
          />

          <div>
            <label htmlFor="audienceSize" className="block text-gray-700 font-semibold mb-2 text-center">
              {t("waitlist.sliderLabel")} {/* Example: "How many members are in your community?" */}
            </label>
            <input
              type="range"
              id="audienceSize"
              name="audienceSize"
              min="5000"
              max={maxMembers}
              step="1000"
              value={formData.audienceSize}
              onChange={handleSliderChange}
              className="w-full accent-teal-500"
              style={{
                background: `linear-gradient(to right, #38bdf8 ${(formData.audienceSize / maxMembers) * 100}%, #e5e7eb ${(formData.audienceSize / maxMembers) * 100}%)`,
                height: "8px",
                borderRadius: "5px",
              }}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span className="text-center text-gray-500">
                {
                  formData.audienceSize >= maxMembers
                    ? t("waitlist.sliderMaxText") // Example: "More than 100,000 members"
                    : `${formatNumberWithCommas(formData.audienceSize)} ${t("waitlist.members")}, ${t("waitlist.approximately")}`
                }

              </span>
              <RiEmotionHappyLine style={{ alignSelf: "flex-end", marginInline: 3, color: "gray" }} size={22} />
            </div>
          </div>

          <button
            type="submit"
            className="w-full bg-gradient-to-r from-green-400 to-teal-500 text-white py-2 rounded-lg font-semibold hover:shadow-lg transform hover:scale-105 transition-all duration-200"
          >
            {t("waitlist.submitButton")} {/* Example: "Submit" */}
          </button>
        </form>
      </div>
    </div>
  );
};

export default WaitlistForm;
